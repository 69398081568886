
var Color= new Array();
Color[1] = "ff";
Color[2] = "ee";
Color[3] = "dd";
Color[4] = "cc";
Color[5] = "bb";
Color[6] = "aa";
Color[7] = "99";

function waittofade() {
	if (document.getElementById('fade')) {
		setTimeout("fadeIn(7)", 1000);
	}
	if (document.getElementById('altfade')) {
		setTimeout("fadeInAlt(7)", 1000);
	}
	if (document.getElementById('fadeHighlight')) {
		setTimeout("fadeInHighlight(1)", 1000);
	}
	
	

}

function fadeIn(where) {
    if (where >= 1) {
      
        document.getElementById('fade').style.backgroundColor = "#ffff" + Color[where];
		  
		 if (where > 1) {
			  where -= 1;
			  setTimeout("fadeIn("+where+")", 200);
		 } else {
			  where -= 1;
			  setTimeout("fadeIn("+where+")", 200);
			  document.getElementById('fade').style.backgroundColor = "";
			
		
		 }
    }
}

function fadeInAlt(where) {
    if (where >= 1) {
      
        document.getElementById('altfade').style.backgroundColor = "#ffff" + Color[where];
		  
		 if (where > 1) {
			  where -= 1;
			  setTimeout("fadeInAlt("+where+")", 200);
		 } else {
			  where -= 1;
			  setTimeout("fadeInAlt("+where+")", 200);
			  document.getElementById('altfade').style.backgroundColor = "";
		
		 }
    }
}

function fadeInHighlight(where) {
	
    if (where <= 7) {
      
        document.getElementById('fadeHighlight').style.backgroundColor = "#ffff" + Color[where];
		  
		 if (where < 7) {
			  where += 1;
			  setTimeout("fadeInHighlight("+where+")", 200);
		 } else {
			  where += 1;
			  setTimeout("fadeInHighlight("+where+")", 200);
			  document.getElementById('fadeHighlight').style.backgroundColor = "";
		
		 }
    }
}

function validateField(fieldId, alertMessage) {
    if (document.getElementById(fieldId).value == "") {
        alert(alertMessage);
        document.getElementById(fieldId).focus();
        return false;
    } else {
        return true;
    }
}

function popup(url,width,height) {
        window.open(url,'popup','width='+width+',height='+height+',scrollbars=auto,resizable=yes,toolbar=no,directories=no,menubar=no,status=no,left=100,top=100');
        return false;
}
